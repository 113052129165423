import * as React from "react"
import Link from "../../utils/link"
import * as styles from "./index.module.less"
import allLinks from "../../../allBlog/links"
import useStore from "../../store"
import { Empty, Pagination } from "antd"
import { createFromIconfontCN } from "@ant-design/icons"
import { useState } from "react"
import { useMemo } from "react"

const IconFont = createFromIconfontCN({
  scriptUrl: "////at.alicdn.com/t/font_3163618_ijmup3g7w9.js",
})

const BlogItem = ({ nodes, tag }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 5

  const { select, knowledgeType } = useStore()
  // 将tag对应的文档进行重组
  const handelTagsData = useMemo(() => {
    // 1、对外链进行过滤
    let TagArr = allLinks.filter(item => {
      return item.frontmatter.tags.includes(tag)
    })
    // 2、过滤nodes中的空数据
    let nodesArr = nodes.filter(item => {
      return item.frontmatter.author
    })
    // 3、合并数组
    TagArr = TagArr.concat(nodesArr)
    // 4、排序
    TagArr.sort((a, b) => {
      return a.frontmatter.order - b.frontmatter.order
    })
    // 5、判断type类型，区分实习生或知识库
    TagArr = TagArr.filter(item => {
      return item.frontmatter.type?.includes(knowledgeType)
    })
    return TagArr
  }, [allLinks, nodes])

  const pageChange = page => {
    setCurrentPage(page)
  }

  return (
    <>
      <ol className={styles.wrapItem}>
        {!handelTagsData.length ? (
          <Empty
            className={styles.wrapItemOther}
            description={select === "前端" ? "暂无数据" : "正在建设中..."}
          />
        ) : (
          <>
            {handelTagsData
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((post, index) => {
                const title = post.frontmatter.title || post.fields.slug

                return (
                  <li key={index} className={styles.item}>
                    <article
                      className={styles.postListItem}
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <header>
                        <div className={styles.title}>
                          <Link
                            to={post.fields.slug}
                            itemProp="url"
                            target="_blank"
                          >
                            <span
                              itemProp="headline"
                              style={{
                                color: post.frontmatter.author ? "" : "#226E4B",
                              }}
                            >
                              {post.frontmatter.author ? null : (
                                <IconFont
                                  type={"icon-link"}
                                  className={styles.icon}
                                />
                              )}

                              {title}
                            </span>
                          </Link>
                        </div>
                        <small className={styles.detailItem}>
                          <p>
                            {post.frontmatter.tags.map(item => (
                              <span key={item}>
                                {`#${item}`}&nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                            ))}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </p>
                          <div className={styles.intro}>
                            <b>{post.frontmatter.author}</b>
                            &nbsp;&nbsp;
                            {post.frontmatter.author ? <b>发布于</b> : ""}
                            &nbsp;&nbsp;
                            <span>{post.frontmatter.date}</span>
                          </div>
                        </small>
                      </header>
                      <section>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                        />

                        <p>
                          <Link
                            to={post.fields.slug}
                            itemProp="url"
                            target="_blank"
                          >
                            {post.frontmatter.author ? "继续阅读" : "点击跳转"}
                            &gt;
                          </Link>
                        </p>
                      </section>
                    </article>
                  </li>
                )
              })}
          </>
        )}
      </ol>
      <Pagination
        defaultCurrent={1}
        total={handelTagsData.length}
        onChange={pageChange}
        pageSize={5}
        className={styles.pagination}
      />
    </>
  )
}
export default BlogItem
