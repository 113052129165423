import * as styles from "./index.module.less"
import * as React from "react"
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"
import { Menu } from "antd"
import UseStore from "../../store"
import { createFromIconfontCN } from "@ant-design/icons"
import classnames from "classnames"

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3163618_ca9sqs0py4c.js",
})
const iconArr = [
  "icon-caozuo-zhinan",
  "icon-shujukaifa",
  "icon-number",
  "icon-number-b-2",
  "icon-number2",
  "icon-number1",
]
const { SubMenu } = Menu

const SideMenuComponents = ({ tag }) => {
  const { Data, knowledgeType } = UseStore()
  // 判断菜单默认打开的值
  let tagData
  if (knowledgeType === "前端入职培训") {
    tagData = Data.trainee
  } else if (knowledgeType === "前端知识库") {
    tagData = Data.knowledge
  } else {
    tagData = []
  }
  // 获取默认打开的key值
  let key
  tagData.forEach(item => {
    if (item.stepTags.includes(tag)) {
      key = item.step
    }
  })
  return (
    <div>
      <div className={styles.list}>
        {knowledgeType === "前端入职培训" || knowledgeType === "后端入职培训"
          ? "培训计划"
          : "目录"}
      </div>
      <div
        className={classnames({
          [`${styles.content}`]: true,
        })}
      >
        <Menu
          mode="inline"
          defaultOpenKeys={[`${key}`]}
          selectedKeys={[`${tag}`]}
        >
          {tagData.map((item, index) => (
            <SubMenu
              key={item.step}
              icon={
                <IconFont type={iconArr[index]} style={{ fontSize: "20px" }} />
              }
              title={`${item.step}`}
            >
              {item.stepTags.map(item => (
                <Menu.Item key={item}>
                  <Link to={`/tags/${kebabCase(item)}`}>{item}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ))}
        </Menu>
      </div>
    </div>
  )
}
export default SideMenuComponents
