// extracted by mini-css-extract-plugin
export var BottomContent = "tags-module--BottomContent--88860";
export var arrowBox = "tags-module--arrowBox--3d8b2";
export var bottom = "tags-module--bottom--09ff8";
export var bottomAfter = "tags-module--bottomAfter--5d02a";
export var bottomArrow1 = "tags-module--bottom-arrow1--96f12";
export var bottomArrow2 = "tags-module--bottom-arrow2--bb421";
export var changeWord = "tags-module--changeWord--cf671";
export var detail = "tags-module--detail--9ce11";
export var detailContent = "tags-module--detailContent--8d88f";
export var pullDown = "tags-module--pullDown--6b5cc";
export var wrapBread = "tags-module--wrapBread--e50aa";
export var wrapContent = "tags-module--wrapContent--cd3ed";
export var wrapSide = "tags-module--wrapSide--63f69";