// 外链整理
const links = [
  {
    //   "编码规范"
    title: "ESLint、行业推荐的规范库",
    tags: ["编码规范"],
    url: "http://alloyteam.github.io/CodeGuide/",
    order: 0,
    type: ["前端入职培训"],
  },
  //   "设计（还原）规范"
  {
    title: "HJ 前端规范",
    tags: ["设计（还原）规范"],
    url: "https://www.kdocs.cn/p/110843716395",
    order: 0,
    type: ["前端入职培训"],
  },
  {
    title: "HJ Web 后台前端规范",
    tags: ["设计（还原）规范"],
    url: "https://www.kdocs.cn/p/108808497768",
    order: 1,
    type: ["前端入职培训"],
  },
  {
    title: "HJ UX 规范",
    tags: ["设计（还原）规范"],
    url: "https://www.kdocs.cn/ent/695885142/1333211875/110798451027",
    order: 2,
    type: ["前端入职培训"],
  },
  //   "用户体验规范"
  {
    title: "Antd设计价值观",
    tags: ["用户体验规范"],
    url: "https://ant.design/docs/spec/introduce-cn",
    order: 0,
    type: ["前端入职培训"],
  },
  //   React
  {
    title: "React 官方文档/教程",
    tags: ["React"],
    url: "https://react.docschina.org/",
    order: 0,
    type: ["前端入职培训"],
  },
  {
    title: "React 视频教程",
    tags: ["React"],
    url: "https://www.bilibili.com/video/BV1g4411i7po",
    order: 1,
    type: ["前端入职培训"],
  },
  {
    title: "React Hooks 视频教程",
    tags: ["React"],
    url: "https://www.bilibili.com/video/BV1y4411Q7yH",
    order: 2,
    type: ["前端入职培训"],
  },
  {
    title: "React 学习资源汇总",
    tags: ["React"],
    url: "https://juejin.cn/post/7017645909483716615",
    order: 3,
    type: ["前端入职培训"],
  },
  // typescript
  {
    title: "TypeScript 视频教程",
    tags: ["TypeScript"],
    description: "提取码: tla3",
    url: "https://pan.baidu.com/s/12i3KIRpeyL76Qpdoj8wa1A",
    order: 0,
    type: ["前端入职培训"],
  },
  // React Router
  {
    title: "React Router 官方文档",
    tags: ["React Router"],
    url: "https://reactrouter.com/",
    order: 0,
    type: ["前端入职培训"],
  },
  // Zustand
  {
    title: "Zustand文档",
    tags: ["Zustand"],
    url: "https://github.com/pmndrs/zustand",
    order: 0,
    type: ["前端入职培训"],
  },
  {
    title: "Zustand源码解析",
    tags: ["Zustand"],
    url: "https://zhuanlan.zhihu.com/p/353135461",
    order: 1,
    type: ["前端入职培训"],
  },
  {
    title: "精读《zustand 源码》",
    tags: ["Zustand"],
    url: "https://github.com/ascoders/weekly/blob/master/%E6%BA%90%E7%A0%81%E8%A7%A3%E8%AF%BB/227.%20%E7%B2%BE%E8%AF%BB%E3%80%8Azustand%20%E6%BA%90%E7%A0%81%E3%80%8B.md",
    order: 2,
    type: ["前端入职培训"],
  },
  // Antd
  {
    title: "Antd UI 组件库",
    tags: ["Antd"],
    url: "https://ant.design/components/overview-cn/",
    order: 1,
    type: ["前端入职培训"],
  },
  //规范
  {
    title: "HJ UX 规范",
    tags: ["规范"],
    url: "https://www.kdocs.cn/ent/695885142/1333211875/110798451027",
    order: 0,
    type: ["前端入职培训"],
  },
  {
    title: "Git 提交规范",
    tags: ["规范"],
    url: "https://zhuanlan.zhihu.com/p/182553920",
    order: 3,
    type: ["前端入职培训"],
  },
  //GraphQL
  {
    title: "GraphQL 官方文档",
    tags: ["GraphQL"],
    url: "https://ant.design/components/overview-cn/",
    order: 0,
    type: ["前端入职培训"],
  },
  // ReactNative
  {
    title: "React Native 环境搭建",
    tags: ["React Native"],
    url: "https://reactnative.cn/docs/environment-setup",
    order: 0,
    type: ["前端入职培训"],
  },
  {
    title: "内置组件",
    tags: ["React Native"],
    url: "https://reactnative.cn/docs/components-and-apis",
    order: 1,
    type: ["前端入职培训"],
  },
  {
    title: "深入了解",
    tags: ["React Native"],
    url: "https://supercodepower.com/docs/react-native-upgrade/index",
    order: 2,
    type: ["前端入职培训"],
  },
  {
    title: "工程化实践",
    tags: ["React Native"],
    url: "https://github.com/listenzz/MyApp",
    order: 3,
    type: ["前端入职培训"],
  },
]
//将外链格式换成tags页面获取数据的格式，方便渲染
const newLinks = links.map(item => {
  let Obj = {
    fields: { slug: item.url },
    frontmatter: {
      author: null,
      date: null,
      description: item.description || null,
      title: item.title,
      order: item.order,
      tags: item.tags,
      type: item.type,
    },
  }
  return Obj
})

export default newLinks
