import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import * as styles from "../styles/tags.module.less"
import useStore from "../store/index"
import kebabCase from "lodash/kebabCase"
// antd
import { Drawer, Breadcrumb } from "antd"
import "antd/dist/antd.css"
// Components
import MainLayout from "../components/mainLayout"
import Seo from "../components/seo"
import BlogItem from "../components/blogItem"
import SideMenu from "../components/sideMenu"
const Tags = ({ pageContext, data, location }) => {
  // tag为当前路由的tag
  const { tag } = pageContext

  // 获取store中的数据
  const { knowledgeType, Data } = useStore.getState()
  // 修改store值
  const { setState } = useStore
  setState({ tag, lastPathName: location.pathname })

  // 判断菜单默认打开的值
  let tagData
  if (knowledgeType === "前端入职培训") {
    tagData = Data.trainee
  } else if (knowledgeType === "前端知识库") {
    tagData = Data.knowledge
  } else {
    tagData = []
  }

  // 获取tag对应的数据
  const { nodes } = data.allMarkdownRemark

  // 设置显示或隐藏状态
  const [menuToggle, setMenuToggle] = useState(false)

  // antd组件draw抽屉相关操作
  const [visible, setVisible] = useState(false)

  // 将抽屉改为显现状态，并改变箭头样式
  const showDrawer = () => {
    setMenuToggle(!menuToggle)
    setVisible(!visible)
  }
  // 关闭抽屉
  const onClose = () => {
    setVisible(false)
  }
  // 获取一级菜单默认展开key值,展开项的第一项
  let key, firstTag
  tagData.forEach(item => {
    if (item.stepTags.includes(tag)) {
      key = item.step
      firstTag = item.stepTags[0]
      setState({ key, firstTag })
    }
  })

  return (
    <div>
      <Seo title={`前端${tag}`} />
      <MainLayout path={location.pathname}>
        <div className={styles.wrapBread}>
          <div className={styles.pullDown}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/frontEnd">前端</Link>
              </Breadcrumb.Item>
              {knowledgeType === "前端入职培训" ? (
                <Breadcrumb.Item>
                  <Link to="/tags/react">入职培训</Link>
                </Breadcrumb.Item>
              ) : null}
              {tagData.length ? (
                <Breadcrumb.Item>
                  <Link to={`/tags/${kebabCase(firstTag)}`}>{key}</Link>
                </Breadcrumb.Item>
              ) : null}

              <Breadcrumb.Item>{tag}</Breadcrumb.Item>
            </Breadcrumb>
            <div
              className={styles.arrowBox}
              aria-hidden="true"
              onClick={showDrawer}
            >
              <span className={styles.changeWord}> 切换</span>
              <span className={menuToggle ? styles.bottom : styles.bottomAfter}>
                <i className={styles.bottomArrow1}></i>
                <i className={styles.bottomArrow2}></i>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.BottomContent}>
          <div className={styles.wrapContent}>
            <div className={styles.wrapSide}>
              <SideMenu pathname={location.pathname} tag={tag} />
            </div>
            <div className={styles.detail}>
              <div className={styles.detailContent}>
                <Drawer
                  placement="top"
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                  getContainer={false}
                  style={{ position: "absolute" }}
                  height="350px"
                >
                  <SideMenu pathname={location.pathname} tag={tag} />
                </Drawer>
                <BlogItem nodes={nodes} tag={tag} />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  )
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          author
          order
          tags
          type
        }
      }

      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
